import React from 'react';

const ArrowV = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '9'} height={props.height || '12'} viewBox="0 0 9 12">
        <g fill="none" fillRule="evenodd" transform="scale(1.2 1.2)">
            <g fill="#131415">
                <path d="M3.537.071l3.124 3.123c.069.07.09.174.052.265-.037.09-.125.15-.223.15H.242c-.098 0-.186-.06-.224-.15-.037-.091-.016-.195.053-.265L3.194.071C3.24.026 3.301 0 3.366 0c.064 0 .126.026.171.071zM3.194 9.93L.071 6.806C0 6.736-.02 6.632.018 6.54c.038-.09.126-.15.224-.15H6.49c.098 0 .186.06.223.15.037.091.017.195-.052.265L3.537 9.929c-.045.045-.107.071-.171.071-.065 0-.126-.026-.172-.071z" transform="translate(-522 -614) translate(522 614)" />
            </g>
        </g>
    </svg>
);

export default ArrowV;