// /*
//  * Global keyboard (history, reference search form)
//  */
import { find, findIndex, isObject, map } from 'lodash';
import { createContext, useState } from 'react';
import { Howl, Howler } from 'howler';

import { httpAPI } from '@mirinae/react-auth';
import { apiPaths } from '@mirinae/lyric-explorer/modules/defines/paths';

export const PlaylistContext = createContext({});

// initializing input state
// const inputStateInit = {
//     useVirtualKeyboard: 'false',
//     show: false,
//     sourceText: '',
// };

const medias = [
    'https://dev4.vmeeting.or.kr/custom-sounds/eight.mp3',
    'https://dev4.vmeeting.or.kr/custom-sounds/strawberry_moon.mp3'
];

export function usePlaylistContext() {
    const [state, setState] = useState({
        mode: 'thumb',  // thumb, bar, list
        position: null,
        index: -1,     // current playing cursor of data.list
        data: {},
    });

    const updateState = (update) => {
        console.log('updateState:', update);
        // console.trace();
        setState((prev) => ({ ...prev, ...update }));
        // console.log('Playlist updated:', update, state);
    };

    const addPlaylist = _id => {
        console.log('addPlaylist:', state, _id);
        if (!find(state.data?.list, { _id })) {
            // call save playlist api
            const params = { data: {
                _id: state.data._id,
                list: [...map(state.data.list, '_id'), _id]
            } };

            httpAPI('', apiPaths.savePlaylist, params).then(resp => {
                updateState({
                    data: {
                        ...resp.data,
                        list: map(resp.data.list, (item, index) => ({ ...item, ...state.data.list[index] }))
                    }
                });
                console.log('Result:', resp.data);
            }).catch(e => {
                console.error(e.message);
            });
        } else {
            console.log(`${_id} is already exists in playlist.`);
        }
    }

    const savePlaylist = (list) => {
        if (!list) {
            console.error('list parameter is empty.');
            return;
        }

        const params = { data: {
            _id: state.data._id,
            list: map(list, '_id')
        } };

        // console.log('savePlaylist:', params);

        return httpAPI('', apiPaths.savePlaylist, params).then(resp => {
            const data = {
                ...resp.data,
                list: map(resp.data.list, (item, index) => ({ ...item, ...list[index] }))
            }

            if (state.index === -1) {
                updateState({ data, index: 0 });
            } else {
                const newIndex = findIndex(data.list, { _id: state.data.list[state.index]._id });
                updateState({ data, index: newIndex });
            }
            // console.log('Result:', resp.data);
        }).catch(e => {
            console.error(e.message);
        });
    }

    const getPlaylist = () => {
        httpAPI('', apiPaths.getPlaylist, { method: 'get' }).then(resp => {
            updateState({ data: resp.data, index: resp.data.list.length ? 0 : -1 });
            console.log('playlist:', resp.data);
        }).catch(e => {
            console.error(e.message);
        });
    }

    const play = index => {
        let sound;

        const music = state.data.list[index ?? state.index];

        if (music.howl) {
            sound = music.howl;
        } else {
            sound = music.howl = new Howl({
                src: music.media[0]?.url || medias[(index ?? state.index) % medias.length],
                html5: true,
                onplay: function(id) {
                    updateState({ playlistIsPlaying: true });
                },
                onend: function() {
                    skip('next');
                },
            });
        }

        sound.play();
        updateState({ index: index ?? state.index });
    }

    const pause = (index) => {
        const music = state.data?.list?.[index ?? state.index];
        if (music?.howl) {
            music.howl.pause();
            updateState({ playlistIsPlaying: false });
        }
    }

    const skip = direction => {
        let index = 0;

        if (direction === 'prev') {
            index = state.index - 1;
            if (index < 0) {
                index = state.data.list.length - 1;
            }
        } else {
            index = state.index + 1;
            if (index >= state.data.list.length) {
                index = 0;
            }
        }

        skipTo(index);
    }

    const skipTo = index => {
        if (state.data.list[state.index].howl) {
            state.data.list[state.index].howl.stop();
        }

        play(index);
    }

    const isPlaying = (index) => {
        const sound = state.data?.list?.[index ?? state.index]?.howl;

        if (!sound) return false;

        const is_playing = sound.playing();
        console.log('isPlaying:', index ?? state.index, is_playing);

        return is_playing;
    }

    const length = () => {
        return state.data?.list?.length || 0;
    }

    const showMessage = message => {
        updateState({ message });
    }

    // PlaylistContext value
    return {
        ...state,
        update: updateState,
        load: getPlaylist,
        add: addPlaylist,
        save: savePlaylist,
        play,
        pause,
        skip,
        skipTo,
        showMessage,
        isPlaying,
        length,
    };
}
