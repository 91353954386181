import React from 'react';

const IconLink = ({ width, height, color }) => (
    <svg width={width || '14'} height={height || 14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill={color || '#525252'} d="M7.01694 9.6196L6.30138 10.3352C5.56379 11.0002 4.43463 10.9712 3.73207 10.2693C3.02951 9.56736 2.99957 8.43823 3.66394 7.70005L4.3795 6.98449C4.68534 6.67908 4.68569 6.18356 4.38027 5.87771C4.07486 5.57187 3.57934 5.57152 3.2735 5.87693L2.55872 6.59249C1.22001 7.9312 1.22001 10.1017 2.55872 11.4404C3.89743 12.7791 6.0679 12.7791 7.40661 11.4404L8.12216 10.7256C8.42758 10.4204 8.42775 9.9254 8.12255 9.61999C7.81735 9.31458 7.32235 9.3144 7.01694 9.6196Z" />
        <path fill={color || '#525252'} d="M7.55145 5.34183L5.34023 7.55305C5.03503 7.85825 5.03503 8.35308 5.34023 8.65827C5.64543 8.96347 6.14025 8.96347 6.44545 8.65827L8.65667 6.44705C8.96187 6.14185 8.96187 5.64703 8.65667 5.34183C8.35147 5.03663 7.85665 5.03663 7.55145 5.34183Z" />
        <path fill={color || '#525252'} d="M11.4391 2.55972C10.7963 1.91677 9.92434 1.55556 9.01516 1.55556C8.10598 1.55556 7.23404 1.91677 6.59121 2.55972L5.87566 3.27449C5.57025 3.57969 5.57007 4.07469 5.87527 4.3801C6.18047 4.68552 6.67547 4.68569 6.98088 4.38049L7.69644 3.66494C8.43403 2.99992 9.56319 3.02886 10.2657 3.73079C10.9683 4.43273 10.9982 5.56187 10.3339 6.30005L9.61833 7.0156C9.31248 7.32102 9.31213 7.81654 9.61755 8.12238C9.92296 8.42822 10.4185 8.42857 10.7243 8.12316L11.4391 7.4076C12.082 6.76477 12.4433 5.89284 12.4433 4.98366C12.4433 4.07448 12.082 3.20255 11.4391 2.55972Z" />
    </svg>
);

export default IconLink;