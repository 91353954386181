import React from 'react';

const Search = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '12'} height={props.height || '12'} viewBox="0 0 12 12">
        <g fill="none" fillRule="evenodd">
            <g fill={props.color || '#131415'}>
                <path d="M8.168 1.411C6.426-.343 3.645-.479 1.743 1.098-.16 2.673-.56 5.445.817 7.5c1.377 2.056 4.081 2.723 6.248 1.541l2.866 2.74c.31.296.8.29 1.103-.014l.736-.74c.307-.31.307-.812 0-1.122L8.99 7.103c1.008-1.87.675-4.186-.82-5.692zm-1.42 5.373c-.793.798-1.986 1.037-3.022.605-1.036-.432-1.711-1.448-1.711-2.576 0-1.128.675-2.145 1.711-2.577 1.036-.431 2.229-.193 3.021.605 1.08 1.09 1.08 2.853 0 3.943z" transform="translate(-267 -725) translate(267 725)" />
            </g>
        </g>
    </svg>
);

export default Search;
