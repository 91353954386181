import React from 'react';
import styled from 'styled-components/macro';

import { palette } from '../../defines/styles';

const Button = styled.button`
    position: relative;
    height: 21px;
    padding: 0 20px;
    color: ${props => (props.theme === 'danger' ? palette.white : palette.green.middle)};
    font-size: 12px;
    font-weight: 700;
    border-radius: 10px;
    background-color: ${props => (props.theme === 'danger' ? palette.red.light : palette.green.light)};
    white-space: nowrap;
    border: 0;
    font-family: inherit;

    &:hover:not(:disabled) {
        color: ${palette.white};
        background-color: ${props => (props.theme === 'danger' ? palette.point.red : palette.green.middle)};
    }
    &:disabled {
        color: ${palette.white};
        background-color: ${palette.gray.light};
    }
    ${props => props.state === 'hover' && (`
        color: ${palette.white};
        background-color: ${props.theme === 'danger' ? palette.point.red : palette.green.middle};
    `)}
`;

const DefaultButton = props => (
    <Button type="button" {...props}>{props.children}</Button>
);

export default DefaultButton;