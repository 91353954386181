import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import IconArrowV from '../icons/ArrowV';
import { palette } from '../../defines/styles';

const SelectFormWrapper = styled.div`
    position: relative;
    width: 100%;
    ${props => props.style};
`;
const SelectedItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    svg {
        margin-top: -2px;
    }
`;
const ItemText = styled.span`
    width: 100%;
    height: 19px;
    border-bottom: 1px solid ${palette.gray.dark};
    cursor: pointer;
    font-size: 11px;
`;
const SelectedLists = styled.ul`
    z-index: ${props => props.zIndex};
    position: absolute;
    left: 0;
    top: -8px;
    width: 100%;
    border: 1px solid ${palette.gray.light};
    border-radius: 4px;
    background-color: ${palette.white};
`;
const ListItem = styled.li`
    height: 28px;
    line-height: 28px;
    padding-left: 5px;
    font-size: 11px;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    ${props => props.selected && (`
        color: ${palette.point.blue};
        font-weight: 700;
    `)}
`;

const SelectForm = props => {
    const [displayLayer, setDisplayLayer] = useState(false);
    const [value, setValue] = useState(props.children);
    const handleClick = ele => {
        setValue(ele);
        if (props.onClick) props.onClick(ele);
        setDisplayLayer(false);
    };
    const handleClose = e => {
        if (e.target.dataset.prevent === 'true') return;
        setDisplayLayer(false);
    };

    useEffect(() => {
        setValue(props.children);
    }, [props.children]);

    useEffect(() => {
        document.addEventListener('click', handleClose, true);
        return () => {
            document.removeEventListener('click', handleClose, true);
        };
    }, []);

    return (
        <SelectFormWrapper style={props.style}>
            <SelectedItem onClick={() => setDisplayLayer(true)}>
                <ItemText>{value}</ItemText>
                <IconArrowV />
            </SelectedItem>
            {displayLayer && (
                <SelectedLists zIndex={props.style ? parseInt(props.style.zIndex, 10) + 1 : '1'}>
                    {props.data && props.data.map((ele, i) => (
                        <ListItem data-prevent="true" key={i} selected={ele === props.children} onClick={() => handleClick(ele)}>{ele}</ListItem>
                    ))}
                </SelectedLists>
            )}
        </SelectFormWrapper>
    );
};

export default SelectForm;