import React from 'react';

const Dots = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '10'} height={props.height || '10'} viewBox="0 0 10 10">
        <g fill="none" fillRule="evenodd">
            <g fill={props.color || '#3C3C3B'}>
                <path d="M8 10c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zm0-6c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zm-6 6c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zm0-6c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z" transform="translate(-573 -118) translate(553 93) translate(20 20) translate(0 5)" />
            </g>
        </g>
    </svg>
);

export default Dots;