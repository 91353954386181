import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

export const useAppVersionStore = create((set, get) => ({
    isAppChecked: false,
    isApp: false,
    versionParam: '',
    appVersion: 0,
    isProEnabledVersion: false,
    isPricePlan3PlusEnabled: false,
    isAdMobAdsEnabled: false,
    displayMustUpgradeApp: null,

    methods: {
        setVersionData: (data) => set({ ...data }),
    },
}));

export const useAppVersionStoreMethods = () =>
    useAppVersionStore((state) => state.methods, shallow);
export const useAppVersionStoreAndMethods = (stateGetter, flags) => [
    useAppVersionStore(stateGetter, flags),
    useAppVersionStore((state) => state.methods, shallow),
];

