import React from 'react';

const IconChat = ({ width, height, color }) => (
    <svg width={width || '15'} height={height || '14'} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill={color || '#3C3C3B'} fillRule="evenodd" clipRule="evenodd" d="M4 0C1.79086 0 0 1.79086 0 4V7.25C0 9.45914 1.79086 11.25 4 11.25H5.98425L6.80713 12.6753C7.11505 13.2086 7.88485 13.2086 8.19277 12.6753L9.01566 11.25H11C13.2091 11.25 15 9.45914 15 7.25V4C15 1.79086 13.2091 0 11 0H4Z" />
        <circle cx="3.75" cy="5.75" r="0.75" fill="white" />
        <circle cx="7.25" cy="5.75" r="0.75" fill="white" />
        <circle cx="10.75" cy="5.75" r="0.75" fill="white" />
    </svg>
);

export default IconChat;