import React from 'react';
import styled from 'styled-components/macro';

import IconTimes from '../icons/Times';

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    padding: 0;
    border: 0;
    background: transparent;
    svg {
        vertical-align: middle;
        path {
            fill: #7b7b7b;
        }
    }
    &:hover {
        svg path {
            fill: #363636;
        }
    }
`;

const ClearButton = props => (
    <Button type="button" {...props}>
        <IconTimes width="13" height="13" />
    </Button>
);

export default ClearButton;