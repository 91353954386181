import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import IconCheckbox from '../icons/Checkbox';
import { palette } from '../../defines/styles';

const CheckboxWrapper = styled.span`
    display: inline-block;
    padding-left: 20px;
    line-height: 15px;
    cursor: pointer;
    vertical-align: middle;
    font-size: ${props => (props.shape === 'circle' ? 'inherit' : props.theme === 'listType' ? '12px' : '10px')};
    overflow: hidden;

    svg {
        float: left;
        margin-left: -20px;
        vertical-align: middle;
    }
    ${props => props.theme === 'listType' && (`
        padding-left: 25px;
        svg {
            margin-left: -25px;
        }
    `)}
`;
const TextLabel = styled.span`
    display: inline-block;
    // padding-left: ${props => (props.theme === 'listType' ? '10px' : '5px')};
`;
// const RadioButton = styled.span`
//     position: relative;
//     display: inline-block;
//     width: 16px;
//     height: 16px;
//     margin: 0 5px 3px -20px;
//     border-radius: 50%;
//     border: 1px solid ${props => (props.check ? '#94D0CD' : '#CECECE')};
//     vertical-align: middle;
//     text-align: center;

//     ${props => (props.checked && `
//         :after {
//             position: absolute;
//             display: inline-block;
//             width: 9px;
//             height: 9px;
//             border-radius: 50%;
//             background-color: #94D0CD;
//         }
//     `)}
// `;

const Radio = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="7" stroke="#CECECE"/>
    </svg>
);
const RadioActive = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="4.5" fill="#94D0CD"/>
        <circle cx="8" cy="8" r="7" stroke="#94D0CD"/>
    </svg>
);

const CheckBoxForm = props => {
    {/*
     * type: 'radio' | null[default] - square design
     * theme: imported in "ReferenceCategory.js" in front-end-client
     * shape: 'circle' | null[default]
     */}
    const [checked, setChecked] = useState(props.value);

    useEffect(() => {
        setChecked(props.value);
    }, [props.value]);

    return (
        <CheckboxWrapper
            theme={props.theme}
            shape={props.shape}
            onClick={() => {
                setChecked(!checked);
                if (props.onClick) {
                    if (props.type === 'radio') {
                        props.onClick(props.name, !checked);
                    } else {
                        props.onClick(!checked);
                    }
                }
            }}>
            {props.shape === 'circle' ? 
                checked ? <RadioActive /> : <Radio />
            : (
                <IconCheckbox color={checked ? palette.green.middle : palette.gray.light} />
            )}
            {props.children && <TextLabel theme={props.theme}>{props.children}</TextLabel>}
        </CheckboxWrapper>
    );
};

export default CheckBoxForm;