export const localPaths = {
    home: '',
    listing: 'listing',
    group: 'group',
    song: 'song',
    artists: 'artists',
    artist: 'artist',
    music: 'music',
};

export const apiPaths = {
    saveSong: '/api/content/song/save',
    getSong: '/api/content/song/get',
    listSongs: '/api/content/song/list',
    deleteSong: '/api/content/song/delete',
    viewSong: '/api/content/song/view',
    bookmarkSong: '/api/content/song/bookmark',
    imageUpload: '/api/content/image/upload',
    analyze: 'api/nlp/lib/analyze',
    importLyrics: '/api/content/importlyrics',
    savePlaylist: '/api/content/playlist/save',
    getPlaylist: '/api/content/playlist/get',
};
