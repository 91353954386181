import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import { os } from '@mirinae/js-utils/utils/userAgent';
import { zIndex, fontDefault } from '../../defines/styles';

import IconLink from '../icons/Link';
import IconCheckCircle from '../icons/CheckCircle';

const MessageWrapper = styled.div`
    pointer-events: none;
    z-index: ${zIndex.hint + 1};
    position: ${os.isOthers ? 'fixed' : 'fixed'};
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: ${os.isOthers ? '690px' : '100vh'};
    & > div {
        max-width: 368px;
    }
`;
const TextBubbleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: fit-content;
    height: 50px;
    padding: 0 50px;
    border-radius: 25px;
    white-space: nowrap;
    background-color: #A0C991;
    animation: slideDown 2s ease-out forwards;

    @keyframes slideDown {
        0% { bottom: 0; }
        4% { bottom: 50px; }
        90% { bottom: 50px; opacity: 1 }
        100% { bottom: 50px; opacity: 0 }
    }

    ${os.isOthers ? `
        left: 50%;
        transform: translateX(-50%);
    ` : `
        left: 11px;
        right: 11px;
        margin: auto;
    `}
`;
const TextInner = styled.div`
    height: 16px;
    color: #FBFBFB;
    font-size: 14px;

    svg {
        vertical-align: middle;
        margin: 0 10px 2px 0;
    }
`;

const SnsShareButton = styled.button`
    padding: 0;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    font-weight: 500;
    font-family: ${fontDefault};
    color: inherit;
    pointer-events: fill;

    display: flex;
    align-items: center;
    justify-content: center;
    width: ${props => (props.theme === 'board' ? '25px' : '30px')};
    height: ${props => (props.theme === 'board' ? '25px' : '30px')};
    border-radius: 50%;
    background: #F4F4F4;

    :hover:not(:disabled) {
        background: #DFDFDF;
        svg path {
            fill: #525252;
        }
    }
    :disabled {
        cursor: default;
        background: #F4F4F4;
        svg path {
            fill: #C4C4C4;
        }
    }
    svg {
        pointer-events: none;
    }
`;

const Wrapper = styled.div`
    ${props => (props.theme === 'board' && `
        position: relative;   
    `)}
`;
const TextWrapper = styled.div`
    position: absolute;
    right: 35px;
    top: 0;
    height: 25px;
    line-height: 25px;
    padding: 0 13px;
    border-radius: 13px;
    background-color: #A0C991;
    color: #FBFBFB;
    font-weight: 700;
    font-size: 12px;
`;

let t;
const LinkShare = ({ theme = 'lessonPage', title = 'Copy link to this page', message = 'Link to this page copied' }) => {
    /*
        theme: lessonsPage[default] | board
    */
    const [display, setDisplay] = useState(false);

    const handleCopy = () => {
        const el = document.createElement('input');
        el.value = window.location.href.replace('app.', '').replace('wikinae.', 'wikinae');;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        setDisplay(true);
    };

    useEffect(() => {
        clearTimeout(t);
        t = setTimeout(() => setDisplay(false), 2500);
    }, [display]);
 
    return (
        <Wrapper theme={theme}>
            <SnsShareButton type="button" onClick={handleCopy} disabled={display} title={title}>
                <IconLink color="#A3A3A3" />
            </SnsShareButton>
            {display && (theme === 'lessonPage' && os.isOthers ? (
                <MessageWrapper>
                    <TextBubbleWrapper>
                        {/* Link to this page copied */}
                        <TextInner>
                            <IconCheckCircle />
                            {message}
                        </TextInner>
                    </TextBubbleWrapper>
                </MessageWrapper>
            ) : (
                <TextWrapper>
                    {/* Copied! */}
                    {message}
                </TextWrapper>
            ))}
        </Wrapper>
    );
};

export default LinkShare;