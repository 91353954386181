import React from 'react';

const Checkbox = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '15'} height={props.height || '15'} viewBox="0 0 15 15">
        <g fill="none" fillRule="evenodd">
            <g fill={props.color || '#DFDFDF'}>
                <path d="M1.667 0C.75 0 0 .75 0 1.667v11.666C0 14.25.75 15 1.667 15h11.666C14.25 15 15 14.25 15 13.333V1.667C15 .75 14.25 0 13.333 0H1.667zm0 7.66l1.166-1.122 3 2.885 6.334-6.09 1.166 1.122-7.5 7.212L1.667 7.66z" transform="translate(-535 -665) translate(535 665)" />
            </g>
        </g>
    </svg>
);

export default Checkbox;